<template>
    <div class="h-vh">
        <!--header-->
        <div class="parallax-container">
            <div class="parallax titlebar">
                <div id="titlebar">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <h2>الشروط والأحكام</h2>
                                <span>تعرف اكتر علي هكتار</span>

                                <!-- Breadcrumbs -->
                                <nav id="breadcrumbs" class="pt-4">
                                    <ul>
                                        <li>
                                            <router-link to="/">الرئيسية</router-link>
                                        </li>
                                        <li>الشروط والأحكام</li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <parallax :speed-factor="0.3" breakpoint="(min-width: 80px)">
                <img :src="titleBg" alt="Parallax Image"/>
            </parallax>
        </div>
        <!-- Container -->
        <div class="container">
            <div class="row justify-content-center">
                <!-- Contact Form -->
                <div class="col-md-12" v-html="terms.result">
                </div>
                <!-- Contact Form / End -->
            </div>
        </div>
        <!-- Container / End -->

    </div>
</template>

<script>
    import Parallax from 'vue-parallaxy';
    import { realEstateService } from '../../services';

    export default {
        name: 'termsAndConditions',
        components: {
            Parallax,

        },
        data() {
            return {
                titleBg: require('../../assets/images/home-parallax-2.jpg'),
                terms: {},
            };
        },
        created() {
            this.getTerms();
        },
        methods: {
            getTerms() {
                realEstateService.getTerms().then(res => {
                    this.terms = res.data;
                }, error => {
                    console.log(error);
                });
            },
        },
    };
</script>

<style >
 div.Masthead:after {
        background-color: rgb(61, 186, 126);
        opacity: 0.9;
    }
</style>
